import React, { useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import http from "../../Utilities/Utils";
import notification from "../../Utilities/Notification";

const Footer = (props) => {
  const [Data, setData] = useState({
    email: "",
  });
  const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
  const handleSubmit = () => {
    window.open(" https://bit.ly/3jD3Kqc", "_blank");
  };
  const handleYilab = () => {
    window.open("https://youthinnovationlab.org", "_blank");
  };
  const handleDisaster = () => {
    window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank");
  };
  const handleSubmission = (e) => {
    setSignupbottonclicked(true);

    http
      .post("/signupforupdate/", Data)
      .then((res) => {
        notification.showSuccess("Email received, Thank you!!");
        setData({ email: "" });
        setSignupbottonclicked(false);
        props.history.push("/");
      })
      .catch((err) => {
        let final_err = err.response.data.email[0];
        notification.showError(final_err);
        setSignupbottonclicked(false);
      });
  };
  const handleChange = (e) => {
    setData({ email: e.target.value });
  };
  return (
    <div>
      <section className="footer-banner">
        <div className="container-fluid">
          <div className="row footer-banner-wrapper">
            <div className="col-lg-9 col-md-9 footer-banner-content">
              <h4>
                We are committed to solve the most pressing challenges of our
                society.
                <br />
                We need leaders like you to help us make them resilient.
              </h4>
            </div>
            <div className="col-lg-3 col-md-3 footer-banner-button">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={handleSubmit}
              >
                APPLY HERE
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-main">
        <div className="container-fluid content">
          <div className="row">
            <div className="col-lg-3 col-md-3 column-width">
              {/* <div className="footer-email">
                                <h4>Get in Touch</h4>
                                <Link to="/feedback-form" id="buttom-menu">Fellowship@youthinnovationlab.org</Link>
                            </div> */}
              <div className="footer-DRR">
                <h4>DRR-FELLOWSHIP</h4>
                <Link to="/guidelines" id="buttom-menu-drr">
                  Guidelines
                </Link>
                <br />
                <Link to="/blog-listing" id="buttom-menu-drr">
                  Blogs
                </Link>
                <br />
                <Link to="/fellows" id="buttom-menu-drr">
                  Fellows
                </Link>
                <br />
                <Link to="/faqs" id="buttom-menu-drr">
                  FAQs{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 footer-youthlab column-width">
              <h4>An Initiative of </h4>
              <img src="/YIlab-final-logo.svg" alt="youthlab" />
            </div>
            <div className="col-lg-3 col-md-3 footer-support column-width">
              <h4>Financial Support</h4>
              <img src="/usaid-final.svg" alt="USAID logo" />
            </div>
            <div className="col-lg-3 col-md-3 footer-signup column-width">
              <h4>Follow Us On:</h4>
              <div className="icons">
                <a href="https://www.facebook.com/DRRFellowship">
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/DRRFellowship">
                  <i className="fab fa-twitter"></i>
                </a>
                <Link to="/feedback-form">
                  {" "}
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </Link>
              </div>
              {/* <input type="email" placeholder="Enter your email" name="email" onChange={handleChange} value={Data.email} />

                            {Signupbuttonclicked ? <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmission} disabled ><i className="fa fa-refresh fa-spin fa-fw" />Submitting...</button>
                                : <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmission}>SUBMIT</button>
                            } */}
            </div>
          </div>
        </div>
      </section>
      <section className="footer-copyright">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 footer-copyright-content">
              <img src="/YI.png" alt="yilab" />
              <h4>
                <a onClick={handleDisaster}>
                  Disaster Risk Reduction -Young Leaders Fellowship{" "}
                </a>
                <br />
                is being implemented and managed by{" "}
                <a onClick={handleYilab}>Youth Innovation Lab.</a>{" "}
              </h4>
            </div>
            <div className="col-lg-6 col-md-6 footer-copyright-reserved">
              <p>Copyright 2023 Youth Innovation Lab.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
