import React, { useEffect, useState } from 'react';
import './../PageContainer/FellowDetails.css';
import { Link } from 'react-router-dom';
import http from '../Utilities/Utils';
import notification from './../Utilities/Notification';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Footer from './Footer';
import Header from './Header';



const FellowDetails = (props) => {
    const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
    const handleSubmit = () => {
        window.open(" https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6PvmnTPPcHlNj7sJ0nzSNLxUNUJPSUZOTDg2SFVBS0hETTRRWk8yT1hITC4u ", "_blank")
    }
    const [FellowDetails, setFellowDetails] = useState([]);
    const [Loading, setLoading] = useState(true);
    useEffect(() => {
        http.get('/fellows-details', true)
            .then((res) => {

                setLoading(false)
                setFellowDetails(res.data)
            })
            .catch(err => {
                console.log("error", err);
            })
    }, [])
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }
    const handleSubmission = (e) => {
        setSignupbottonclicked(true)

        http.post('/signupforupdates/', Data)
            .then(res => {
                notification.showSuccess("Email received, Thank you!!");
                setData({ email: '' })
                setSignupbottonclicked(false)
                props.history.push('/')
            })
            .catch(err => {
                let final_err = err.response.data.email[0];
                notification.showError(final_err)
                setSignupbottonclicked(false)
            })
    }
    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }
    const [Data, setData] = useState({
        email: ''
    });
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }
    const Details = props.location.state.details;
    return (
        <div>

            <Header />
            <div className="container-fluid content">
                <div className="row Myrow1">
                    {props.location.state ? <>
                        <div className="col-sm-4 IMG">

                            <img src={props.location.state.image} alt="fellow" />

                        </div>
                        <div className="col-sm-8 H4" id="fellow-name">

                            <h4>{props.location.state.name}</h4>


                            <h6>{props.location.state.address}</h6>

                        </div>


                    </>
                        : ''}
                </div>
                <div className="row Myrow2">
                    {ReactHtmlParser(Details)}






                </div>
                <Footer />
            </div>

        </div>






    )
}
export default FellowDetails;