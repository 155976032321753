import React, { useState } from 'react';
import './FrequentQuestions.css';
import { Link } from 'react-router-dom';
import http from '../Utilities/Utils';
import notification from '../Utilities/Notification';
import Footer from './Footer';
import Header from './Header';

const FrequentQuestions = (props) => {
    const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
    const handleSubmit = () => {
        window.open(" https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6PvmnTPPcHlNj7sJ0nzSNLxUNUJPSUZOTDg2SFVBS0hETTRRWk8yT1hITC4u ", "_blank")
    }

    const handleYouth = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6AQD9FV6xudNkjgJQal_ytpURDNFS0VKSUUyNDA3TFNYMVYwS0VCNTFJUi4u", "_blank")
    }

    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }





    const [btn1, setBtn1] = useState(false)
    const [btn2, setBtn2] = useState(false)
    const [btn3, setBtn3] = useState(false)
    const [btn4, setBtn4] = useState(false)
    const [btn5, setBtn5] = useState(false)
    const [btn6, setBtn6] = useState(false)
    const [btn7, setBtn7] = useState(false)
    const [btn8, setBtn8] = useState(false)
    const [btn9, setBtn9] = useState(false)
    const [btn10, setBtn10] = useState(false)
    const [btn11, setBtn11] = useState(false)
    const [btn12, setBtn12] = useState(false)
    const [btn13, setBtn13] = useState(false)
    const [btn14, setBtn14] = useState(false)
    const [btn15, setBtn15] = useState(false)
    const [btn16, setBtn16] = useState(false)

    const handleOpen1 = () => {
        setBtn1(true)
    }
    const handleClose1 = () => {
        setBtn1(false)
    }
    const handleOpen2 = () => {
        setBtn2(true)
    }
    const handleClose2 = () => {
        setBtn2(false)
    }
    const handleOpen3 = () => {
        setBtn3(true)
    }
    const handleClose3 = () => {
        setBtn3(false)
    }
    const handleOpen4 = () => {
        setBtn4(true)
    }
    const handleClose4 = () => {
        setBtn4(false)
    }
    const handleOpen5 = () => {
        setBtn5(true)
    }
    const handleClose5 = () => {
        setBtn5(false)
    }
    const handleOpen6 = () => {
        setBtn6(true)
    }
    const handleClose6 = () => {
        setBtn6(false)
    }
    const handleOpen7 = () => {
        setBtn7(true)
    }
    const handleClose7 = () => {
        setBtn7(false)
    }
    const handleOpen8 = () => {
        setBtn8(true)
    }
    const handleClose8 = () => {
        setBtn8(false)
    }
    const handleOpen9 = () => {
        setBtn9(true)
    }
    const handleClose9 = () => {
        setBtn9(false)
    }
    const handleOpen10 = () => {
        setBtn10(true)
    }
    const handleClose10 = () => {
        setBtn10(false)
    }
    const handleOpen11 = () => {
        setBtn11(true)
    }
    const handleClose11 = () => {
        setBtn11(false)
    }
    const handleOpen12 = () => {
        setBtn12(true)
    }
    const handleClose12 = () => {
        setBtn12(false)
    }
    const handleOpen13 = () => {
        setBtn13(true)
    }
    const handleClose13 = () => {
        setBtn13(false)
    }
    const handleOpen14 = () => {
        setBtn14(true)
    }
    const handleClose14 = () => {
        setBtn14(false)
    }
    const handleOpen15 = () => {
        setBtn15(true)
    }
    const handleClose15 = () => {
        setBtn15(false)
    }
    const handleOpen16 = () => {
        setBtn16(true)
    }
    const handleClose16 = () => {
        setBtn16(false)
    }
    const [Data, setData] = useState({
        email: ''
    });
    const handleSubmission = (e) => {
        setSignupbottonclicked(true)

        http.post('/signupforupdate/', Data)
            .then(res => {

                notification.showSuccess("Email received, Thank you!!");
                setData({ email: '' })
                setSignupbottonclicked(false)
                props.history.push('/')
            })
            .catch(err => {

                let final_err = err.response.data.email[0];
                notification.showError(final_err)
                setSignupbottonclicked(false)
            })
    }
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }

    return (
        <div>
            <Header />
            <div className="container-fluid content">
                <div className="row  ray-fq  micro-container-guidelines">
                    <div className="guidelines-image">
                        <h4>Frequently<br /> Asked Questions</h4>
                    </div>

                </div>
                <div className="quesn-catagory ">

                    <h4>FAQs</h4>
                    <div className="vl"></div>
                    <a href="#program">Program and Eligibility  </a>
                    <a href="#application" id="application-process">Application Process</a>
                    <a href="#financial" id="financial-info">Financial Information</a>

                </div>
                {/* <div className="row question-list">
                    <div className="col-sm-3 questn">
                        <h4>FAQ</h4>
                    </div>
                    <div className="col-sm-3 questn ">
                        <a href="#">Program and Eligibility</a>
                    </div>
                    <div className="col-sm-3 questn">
                        <a href="#">Application Process</a>
                    </div>
                    <div className="col-sm-3 questn">
                        <a href="#">Financial information</a>
                    </div>
                </div> */}

                <div className="row frequent">
                    <div className="container">

                        <h4 id="program">Program and Eligibility  </h4>


                        {btn1 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">How many fellows will be selected for the DRR-YLF program?</p>
                                    <button onClick={handleClose1}><i className="fa fa-minus"></i></button>
                                </div>

                                <p className="paragraph-ans">The fellowship will consist of a cohort of 15 individuals, one for each project municipalities.</p>

                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >How many fellows will be selected for the DRR-YLF program?</p>
                                    <button onClick={handleOpen1}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn2 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">	Are there specific age requirements? </p>
                                    <button onClick={handleClose2}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">At the time of nomination, you must be 30 years old or younger.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >Are there specific age requirements? </p>
                                    <button onClick={handleOpen2}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }

                        {btn3 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">	Is there a degree requirement?    </p>
                                    <button onClick={handleClose3}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">We are looking for fresh graduates with a bachelor’s degree in degree in Environmental Science, Environmental Engineering, Geomatics Engineering, Disaster Risk Reduction, Development Studies, Natural Resources, Crisis Management, Computer Science, Computer Engineering, Information Technology or related field. Those who have knowledge of technology and understanding of disaster / DRRM/ and related discipline would be a good fit for the program.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >Is there a degree requirement?</p>
                                    <button onClick={handleOpen3}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn4 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">Is there a minimum work experience requirement?  </p>
                                    <button onClick={handleClose4}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">There is no minimum work experience requirement. However, successful candidates will be enthused to work at the municipal level and capacitate the municipality in the disaster information management system.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >Is there a minimum work experience requirement?</p>
                                    <button onClick={handleOpen4}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn5 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">What are the project municipalities of DRR-Fellowship? </p>
                                    <button onClick={handleClose5}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">The project municipalities are (1) Dhankuta Municipality (Dhankuta District), (2) Janakpurdham Sub-metropolitan City (Dhanusha District), (3) Jiri Municipality (Dolakha District), (4) Chautara Sangachokgadhi Municipality (Sindhupalchok District), (5) Melamchi Municipality (Sindhupalchok District), (6) Gorkha Municipality (Gorkha District), (7) Pokhara Metropolitan City (Kaski District), (8) Baglung Municipality (Baglung District), (9) Beni Municipality (Myagdi District), (10) Ghorahi Sub-metropolitan City (Dang District), (11) Madhuban Municipality (Bardiya District), (12) Gurbakot Municipality (Surkhet District), (13) Chamunda Bindrasaini Municipality (Dailekh District), (14) Tikapur Municipality (Kailali District), and (15) Krishnapur Municipality (Kanchanpur District).</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >What are the project municipalities of the DRR-YLF program? </p>
                                    <button onClick={handleOpen5}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }

                        {btn6 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">	Where will DRR fellows be placed?</p>
                                    <button onClick={handleClose6}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">Among the project municipalities, DRR fellows will be placed at their local municipality or the municipality they are willing to relocate.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >Where will DRR fellows be placed?</p>
                                    <button onClick={handleOpen6}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn7 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">Are there any language requirements?</p>
                                    <button onClick={handleClose7}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">Regarding the language requirements, DRR fellows should have good command in English and Nepali. They should be able to communicate well in both languages. We do not require DRR fellows to have knowledge of the local language, but it will be highly desirable.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >Are there any language requirements?</p>
                                    <button onClick={handleOpen7}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn16 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">How long is the fellowship program?</p>
                                    <button onClick={handleClose16}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">The fellowship program is six-month long.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >How long is the fellowship program?</p>
                                    <button onClick={handleOpen16}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn8 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">What is the time commitment? Is this a full-time fellowship?</p>
                                    <button onClick={handleClose8}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">Participation in the program is a full-time commitment. DRR fellows will be working from their respective municipality office and are expected to work during the workdays.   </p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >What is the time commitment? Is this a full-time fellowship?</p>
                                    <button onClick={handleOpen8}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }

                        {btn10 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">What if I am accepted to the program but not able to attend?</p>
                                    <button onClick={handleClose10}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">We ask that you apply to this program once you are absolutely certain to join if selected. In case of an emergency or unforeseeable circumstance that preclude your participation, you must notify the Fellowship Secretariat at fellowship@youthinnovationlab.org as soon as possible.
                                </p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >What if I am accepted to the program but not able to attend?</p>
                                    <button onClick={handleOpen10}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }


                        {/* <div className="container application-process"> */}
                        <h4 id="application">Application Process</h4>

                        {btn11 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">When do I apply? </p>
                                    <button onClick={handleClose11}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">The application deadline for the fellowship is {""} September{""} 13, {""} 2021 by 5 pm.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >When do I apply? </p>
                                    <button onClick={handleOpen11}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }

                        {btn12 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">	How do I apply? </p>
                                    <button onClick={handleClose12}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">You must complete all the required sections of the online application and apply before the deadline. </p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >How do I apply? </p>
                                    <button onClick={handleOpen12}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {btn13 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">What are the selection procedures?</p>
                                    <button onClick={handleClose13}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">All the applications are evaluated by the Fellowship Secretariat at the initial reviewing stages and will be narrowed down to a final selection pool. The short-listed candidates will be invited for an interview and the hiring team will select a cohort of 15 DRR fellows for 15 project municipalities.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >What are the selection procedures?</p>
                                    <button onClick={handleOpen13}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }

                        {/* </div> */}
                        {/* <div className="container application-process"> */}
                        <h4 id="financial">Financial Information</h4>

                        {btn15 ?
                            <div className=" questions-answers">
                                <div className=" ques1">
                                    <p className="paragraph-qus">Am I provided financial support to participate in this program?</p>
                                    <button onClick={handleClose15}><i className="fa fa-minus"></i></button>
                                </div>
                                <p className="paragraph-ans">Fellows will be provided a stipend of NPR 30,000 per month (inclusive of all applicable government taxes), They will be provided allowances for communication, travel, and accommodation for the events organized by YI-Lab.</p>
                            </div>
                            :
                            <div className=" questions-answers">
                                <div className="  ques1">
                                    <p className="paragraph-qus" >Am I provided financial support to participate in this program? </p>
                                    <button onClick={handleOpen15}><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        }
                        {/* </div> */}


                    </div>
                </div>
                <Footer />
                {/* <div className="row Myrow3">
                    <h4>We are committed to solve the most pressing challenges of our society.<br />
                        We need leaders like you to help us make them resilient.
                    </h4>

                    <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmit} >APPLY HERE</button>
                </div>
                <div className="row Myrow4">

                    <div className="col-sm-1 DRR">
                        <h4>DRR-YIL</h4>
                        <Link to="/guidelines" id="buttom-menu-drr" >Guidelines</Link><br />
                        <Link to="/blog-listing" id="buttom-menu-drr">Blogs</Link><br />
                        <Link to="/fellows" id="buttom-menu-drr" >Fellows</Link><br />
                        <Link to="/faqs" id="buttom-menu-drr" >FAQs </Link>

                    </div>
                    <div className="col-sm-2 mail">
                        <h4>Get in Touch </h4>
                        <Link to="feedback-form" id="buttom-menu">Fellowship@youthinnovationlab.org</Link>
                    </div>
                    <div className="col-sm-3 signup">
                        <h4>Sign up for Updates </h4>
                        <input type="email" placeholder="Enter your email" name="email" onChange={handleChange} value={Data.email} />
                        <div>
                            {Signupbuttonclicked ? <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmission} disabled ><i className="fa fa-refresh fa-spin fa-fw" />Submitting...</button>
                                : <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmission}>SUBMIT</button>
                            }
                        </div>
                    </div>
                    <div className="col-sm-3 youth-lab" >
                        <h4>An Initiative of</h4>
                        <img src="YIlab-final-logo.svg" alt="youthlab" />
                    </div>
                    <div className="col-sm-3 support">
                        <h4>Financial Support</h4>
                        <img src="usaid-final.svg" alt="USAID logo" />
                    </div>
                </div>
                <div className="Myrow5">
                    <img src="YI.png" alt="yilab" />
                    <h4><a onClick={handleDisaster}>Disaster Risk Reduction -Young Leaders Fellowship </a><br />is being implemented and managed by <a onClick={handleYilab}>Youth Innovation Lab.</a>  </h4>

                </div> */}
            </div>
        </div >

    )
}

export default FrequentQuestions;