import React, { useState } from 'react';
import './LandingPage.css';
import { Link } from 'react-router-dom';
import http from './../Utilities/Utils';
import { toast } from 'react-toastify';
import notification from '../Utilities/Notification';
import Footer from './Footer';
import Header from './Header';

const LandingPage = (props) => {
    const [Data, setData] = useState({
        email: ''
    });
    const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);



    const handleSubmit = () => {
        window.open("https://bit.ly/3jD3Kqc", "_blank")
    }
    const handleLearn = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-young-leaders-fellowship", "_blank")
    }

    const handleYouth = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6AQD9FV6xudNkjgJQal_ytpURDNFS0VKSUUyNDA3TFNYMVYwS0VCNTFJUi4u", "_blank")
    }
    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }
    const handleSubmission = (e) => {
        setSignupbottonclicked(true)

        http.post('/signupforupdate/', Data)
            .then(res => {
                notification.showSuccess("Email received, Thank you!!");
                setData({ email: '' })
                setSignupbottonclicked(false)
                props.history.push('/')
            })
            .catch(err => {
                let final_err = err.response.data.email[0];
                notification.showError(final_err)
                setSignupbottonclicked(false)
            })
    }
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }

    return (
        <div>
            <Header />
            <div className="container-fluid content">
                <div className="row Myrow1 landing-row">
                    <div className="container-fluid micro-container ">
                        <div className="landing-image">
                            <h4>Creating the pool of DRR Young leaders to improve data driven local<br /> governance and to create resilient communities.
                            </h4>
                            <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmit} >APPLY HERE</button>
                        </div>
                    </div>
                </div>
                <div className="row row2-landing ">
                    <h4>Young Professionals Leading the Change</h4>
                    <p>Disaster Risk Reduction – Young Leaders Fellowship provides DRR enthusiasts who are technically sound with an opportunity to gain in-depth experience in DRRM and related disciplines. It further creates the next generation of DRR Young Professionals to enhance disaster resilience communities.</p>
                    <p>Given the reality that most of the municipalities lack capacities to collect, manage, and use DRR information for informed decision making, DRR-Fellowship aims to address this issue by mobilizing trained local youths with sound knowledge on BIPAD portal in the local governments. DRR fellows act as a connecting bridge to transfer the technical capacity of using BIPAD portal to the local governments. Throughout DRR-Fellowship, DRR fellows will also have exciting opportunities to participate in learning events being organized by leading institutions in the field of DRRM including NDRRMA.
                    </p>

                    <button type="button" className="btn btn-danger btn-sm" onClick={handleLearn}>LEARN MORE</button>
                </div>
                <div className="row pictures">
                    {/* <div className="col-sm-6 pic1">
                        <Link to="/guidelines" id="link-pics"><img src="guidelines.png" alt="guidelines" /></Link>
                    </div>
                    <div className="col-sm-4 pic2">
                        <Link to="/blogs" id="link-pics"><img src="Readblogs.png" alt="readblogs" /> </Link>
                    </div>
                    <div className="col-sm-6 pic3">
                        <Link to="/fellows" id="link-pics"><img src="Meetfellow.png" alt="meetfellows" /> </Link>
                    </div> */}
                    <div className="pic1">
                        <Link to="/guidelines" id="link-pics"><img src="guidelines.png" alt="guidelines" /></Link>
                    </div>
                    <div className="pic2">
                        <Link to="/blog-listing" id="link-pics"><img src="Readblogs.png" alt="readblogs" /> </Link>
                    </div>
                    <div className="pic3">
                        <Link to="/fellows" id="link-pics"><img src="Meetfellow.png" alt="meetfellows" /> </Link>
                    </div>
                </div>

                {/* <div className="row Myrow3 ">
                    <h4>We are committed to solve the most pressing challenges of our society.<br />
                        We need leaders like you to help us make them resilient.
                    </h4>
                    <div>
                        <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmit} >APPLY HERE</button>
                    </div>
                </div> */}
                <Footer />
                {/* <div className="row Myrow4">

                    <div className="col-sm-1 DRR">
                        <h4>DRR-YIL</h4>
                        <Link to="/guidelines" id="buttom-menu-drr" >Guidelines</Link><br />
                        <Link to="/blog-listing" id="buttom-menu-drr">Blogs</Link><br />
                        <Link to="/fellows" id="buttom-menu-drr" >Fellows</Link><br />
                        <Link to="/faqs" id="buttom-menu-drr" >FAQs </Link>

                    </div>
                    <div className="col-sm-2 mail">
                        <h4>Get in Touch </h4>


                        <Link to="/feedback-form" id="buttom-menu">Fellowship@youthinnovationlab.org</Link>
                    </div>

                    <div className="col-sm-3 signup">
                        <h4>Sign up for Updates </h4>

                        <input type="email" placeholder="Enter your email" name="email" onChange={handleChange} value={Data.email} />
                        <div>
                            {Signupbuttonclicked ? <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmission} disabled ><i className="fa fa-refresh fa-spin fa-fw" />Submitting...</button>
                                : <button type="button" className="btn btn-danger btn-sm" onClick={handleSubmission}>SUBMIT</button>
                            }
                        </div>
                    </div>





                    <div className="col-sm-3 youth-lab" >
                        <h4>An Initiative of </h4>
                        <img src="YIlab-final-logo.svg" alt="youthlab" />
                    </div>
                    <div className="col-sm-3 support">
                        <h4>Financial Support</h4>
                        <img src="usaid-final.svg" alt="USAID logo" />
                    </div>
                </div>
                <div className="Myrow5">
                    <img src="YI.png" alt="yilab" />
                    <h4><a onClick={handleDisaster}>Disaster Risk Reduction -Young Leaders Fellowship </a><br />is being implemented and managed by <a onClick={handleYilab}>Youth Innovation Lab.</a>  </h4>

                </div> */}
            </div>
        </div >
    )
}

export default LandingPage;