import React, { useState } from 'react';
import './Feedback.css';
import { Link } from 'react-router-dom';
import http from '../Utilities/Utils';
import notification from '../Utilities/Notification';
import Footer from './Footer';
import Header from './Header';

const Feedback = (props) => {
    const [Feedback, setFeedback] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        query: ''
    })
    const [Submitbuttonclicked, setSubmitbuttonclicked] = useState(false)



    const [Data, setData] = useState({
        email: ''
    });



    const handleSubmit = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6PvmnTPPcHlNj7sJ0nzSNLxUNUJPSUZOTDg2SFVBS0hETTRRWk8yT1hITC4u", "_blank")
    }
    const handleLearn = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }

    const handleYouth = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6AQD9FV6xudNkjgJQal_ytpURDNFS0VKSUUyNDA3TFNYMVYwS0VCNTFJUi4u", "_blank")
    }
    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }
    const handleSubmission = (e) => {
        e.preventDefault();
        setSubmitbuttonclicked(true)
        // if (Feedback.mobile.length !== 10) {
        //      return setSubmitbuttonclicked(false)(notification.showError('Mobile Number must be of 10 digits')) 
        //     }
        // else {
        http.post('/queries/', Feedback)
            .then(res => {

                notification.showSuccess("Your query has been received !!");
                setSubmitbuttonclicked(false)
                props.history.push('/')
            })
            .catch(err => {

                setSubmitbuttonclicked(false)
                let final_err = err.response.data.message;
                notification.showError(final_err)

            })

    }
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }

    const handleName = (e) => {
        setFeedback({
            ...Feedback,
            name: e.target.value
        })
    }
    const handleEmail = (e) => {
        setFeedback({
            ...Feedback,
            email: e.target.value
        })
    }
    const handleMobile = (e) => {
        setFeedback({
            ...Feedback,
            mobile: e.target.value
        })
    }
    const handleSubject = (e) => {
        setFeedback({
            ...Feedback,
            subject: e.target.value
        })
    }
    const handleQueries = (e) => {
        setFeedback({
            ...Feedback,
            query: e.target.value
        })
    }
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }


    return (
        <div>
            <Header />

            <div className="container">
                <div className="row">
                    <form role="form" className="col-md-9 go-right">
                        <h2 id="test">Any Questions?Drop Us a Note!!</h2>

                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input id="name" name="name" placeholder=" Full Name " type="text" className="form-control" onChange={handleName} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input id="email" name="email" type="email" placeholder="Email Address" className="form-control" onChange={handleEmail} />

                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Mobile Number</label>
                            <input id="phone" name="mobile" type="number" placeholder="Mobile Number" className="form-control" onChange={handleMobile} />

                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Subject</label>
                            <input id="phone" name="subject" type="text" placeholder="Subject " className="form-control" onChange={handleSubject} />

                        </div>
                        <div className="form-group">

                            <textarea id="message" name="queries" className="form-control" placeholder="Drop your Note!!" style={{ height: 150 }} required onChange={handleQueries} />

                        </div>
                        <div className="form-group">
                            {Submitbuttonclicked ? <button type="submit" className="btn btn-primary" onClick={handleSubmission} disabled  ><i className="fa fa-refresh fa-spin fa-fw" />Submitting...</button>
                                : <button type="submit" className="btn btn-primary" onClick={handleSubmission} >Submit</button>
                            }

                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>

    )
}

export default Feedback;