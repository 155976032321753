import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import https from '../Utilities/Utils';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';
const Dashboard = (props) => {
    const [Loading, setLoading] = useState(true);
    const [signupUpdates, setsignupUpdates] = useState('');


    useEffect(() => {
        https.get('/signupforupdates', true)
            .then((res) => {
                setsignupUpdates(res.data)
                setLoading(false)

            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    const handleLogout = (e) => {
        localStorage.clear();
        props.history.push('/');
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
                <div className="container">

                    <img src="logo-05.png" alt="tayar-logo" />
                    <h5>DISASTER RISK REDUCTION<br />YOUNG LEADERS FELLOWSHIP</h5>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="#">Landing Page
            <span className="sr-only">(current)</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin-fellow-details" className="nav-link">Fellows</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Blogs</a>
                            </li>
                            <li className="nav-item">
                                <button type="submit" onClick={handleLogout} >Logout</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="row">
                <div className="col-sm-3">
                    <div className="d-flex" id="wrapper">
                        {/* Sidebar */}
                        <div className="bg-light border-right" id="sidebar-wrapper">

                            <div className="list-group list-group-flush">
                                <Link to="/admin-dashboard-signupforupdate" className="list-group-item list-group-item-action bg-light">Signup for Updates</Link>
                                <Link to="/admin-dashboard-getintouch" className="list-group-item list-group-item-action bg-light">Get in touch</Link>

                            </div>
                        </div>
                        {/* /#sidebar-wrapper */}
                    </div>
                </div>
                {Loading ? <div className="load">
                    <Loader />
                    <p>Loading...</p>
                </div>
                    :

                    <div className="col-sm-9">

                        <table id="emp" className="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Email Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {signupUpdates.map((item, id) => {
                                    return <tr key={id}  >
                                        <td>{item._id}</td>
                                        <td>{item.email}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <ReactHTMLTableToExcel
                            className="Exbtn btn-info"
                            table="emp"
                            filename="ReportExcel"
                            sheet="Sheet"
                            buttonText="Download" />
                    </div>
                }
            </div>
        </div >
    )
}

export default Dashboard;