import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import './Getintouch.css';
import http from '../Utilities/Utils';

const Getintouch = (props) => {
    const [Getintouch, setGetintouch] = useState([]);
    const [Loading, setLoading] = useState(true);

    const [Data, setData] = useState({
        name: '',
        details: '',
        image: ''
    })
    const handleLogout = (e) => {
        localStorage.clear();
        props.history.push('/');
    }



    // useEffect(() => {
    //     http.get("/getintouch", true)
    //         .then((res) => {
    //             setGetintouch(res.data)
    //             setLoading(false)
    //         })
    //         .catch(err => {
    //             console.log("error", err)
    //         })
    // }, [])
  
    const handleName = (e) => {
        setData({
            ...Data,
            name: e.target.value
        })
    }
    const handleDetails = (e) => {
        setData({
            ...Data,
            details: e.target.value
        })
    }
    const handleImage = (e) => {
        setData({
            ...Data,
            image: e.target.files[0]
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('image', Data.image);
        form_data.append('name', Data.name);
        form_data.append('details', Data.details);

        http.post('/fellows-details', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                console.log('submitted succesfully', res);


            })
            .catch(err => {
                
                let final_err = err.response.data;
                console.log("whats final error>>>", final_err);

            })

        

        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
                    <div className="container">

                        <img src="logo-05.png" alt="tayar-logo" />
                        <h5>DISASTER RISK REDUCTION<br />YOUNG LEADERS FELLOWSHIP</h5>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="#">Landing Page
            <span className="sr-only">(current)</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Fellows</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Blogs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Faqs</a>
                                </li>
                                <li className="nav-item">
                                    <button type="submit" onClick={handleLogout} >Logout</button>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="row">
                    <div className="col-sm-3 sidebar">
                        <div className="d-flex" id="wrapper">
                            {/* Sidebar */}
                            <div className="bg-light border-right" id="sidebar-wrapper">

                                <div className="list-group list-group-flush">
                                    <Link to="/admin-dashboard-signupforupdate" className="list-group-item list-group-item-action bg-light">Signup for Updates</Link>
                                    <Link to="/admin-dashboard-getintouch" className="list-group-item list-group-item-action bg-light">Get in touch</Link>

                                </div>
                            </div>
                            {/* /#sidebar-wrapper */}
                        </div>
                    </div>
                    {Loading ? <div className="load">
                        <Loader />
                        <p>Loading...</p>
                    </div>
                        : <div className="col-sm-9 query-data">
                            {Getintouch.map((item) => {
                                return (
                                    <div>
                                        <div className="query-form">
                                            <p> key={item._id} </p>
                                            <p>Name:{item.name}</p>
                                            <p>Email:{item.email}</p>
                                            <p>Mobile:{item.mobile}</p>
                                            <p>Subject:{item.subject}</p>
                                            <p>Query:{item.query}</p>
                                        </div>
                                    </div>
                                )


                            })}
                        </div>}
                </div>
            </div>
        )
    }
}
export default Getintouch;