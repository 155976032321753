import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import Guidelines from '../PageContainer/Guidelines';
import Fellows from '../PageContainer/Fellows';
import FellowDetails from '../PageContainer/FellowsDetails';
import Blogs from '../PageContainer/Blogs';
import FrequentQuestions from '../PageContainer/FrequentQuestions';
import LandingPage from '../PageContainer/LandingPage';
import Login from '../Admin Pannel/Login';
import Dashboard from '../Admin Pannel/Dashboard';
import Feedback from '../PageContainer/Feedback';
import FellowsData from '../Admin Pannel/FellowsData';
import Getintouch from '../Admin Pannel/Getintouch';
import ScrollPageControl from './ScrollPageControl';
import OverallFellowsData from '../Admin Pannel/overallFellowData'
import BlogListing from '../PageContainer/BlogListings';





const Routing = () => {

    const ProtectedRoute = ({ component: Component, ...rest }) => {
        return (
            <Route path={rest.path} render={(props) => (
                localStorage.getItem('token')
                    ? <div>

                        <Component {...props} />
                    </div>

                    : <Redirect to='/' /> //To DO additional attributes
            )}></Route >
        )
    }






    return (
        <Router>
          
           <ScrollPageControl/>
            <Route exact path="/" component={LandingPage} ></Route>
            <Route path="/faqs" component={FrequentQuestions} ></Route>
            <Route path="/guidelines" component={Guidelines} ></Route>
            <Route path="/blogs/:id" component={Blogs} ></Route>
            <Route path="/blog-listing" component={BlogListing}></Route>
            <Route path="/fellows" component={Fellows} ></Route>
            <Route path="/fellows-details" component={FellowDetails}></Route>
            {/* <Route path="/admin" component={Login}></Route> */}
            <Route path="/feedback-form" component={Feedback}></Route>
            <ProtectedRoute path="/admin-dashboard-signupforupdate" component={Dashboard} ></ProtectedRoute>
            <ProtectedRoute path="/admin-dashboard-getintouch" component={Getintouch}></ProtectedRoute>
            <ProtectedRoute path="/admin-fellow-details" component={FellowsData}></ProtectedRoute>
            <ProtectedRoute path="/admin-fellow-overall-data" component={OverallFellowsData}></ProtectedRoute>
          
        </Router>
    )
}

export default Routing;