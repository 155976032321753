import React, { useState, useEffect } from 'react';
import http from '../Utilities/Utils';
import { Link } from 'react-router-dom';
import './FellowData.css';
import notification from './../Utilities/Notification';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const OverallFellowsData = (props) => {
    const [Loading, setLoading] = useState(true);
    const [Imagetest, setImagetest] = useState([]);
    const[Value,setValue]=useState('');
    const [Data, setData] = useState({
        name: '',
        address:'',
        details: '',
        heading: '',
        image: ''
    })
    const [selectedFellowId,setSelectedFellowId]=useState('');
    const [selectedFellowDetails,setSelectedFellowDetails]=useState([]);
    const[isEditButnClicked,setIsEditButnClicked]=useState(false)
    const[dataUpdateFlag,setDataUpdateFlag]=useState(false)
    const handleName = (e) => {
        setData({
            ...Data,
            name: e.target.value
        })
    }
    const handleAddress=(e)=>{
        setData({
            ...Data,
            address:e.target.value
        })
    }
    const handleDetails = (e) => {
        setData({
            ...Data,
            details: e.target.value
        })
    }
    const handleHeading = (e) => {
        setData({
            ...Data,
            heading: e.target.value
        })
    }
    const handleImage = (e) => {
        setData({
            ...Data,
            image: e.target.files[0]
        })
       
    }
    const [imageclear, setImageClear] = useState(false);
    const handleUpdate = (e) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('image', Data.image);
        form_data.append('name', Data.name);
        form_data.append('address',Data.address);
        form_data.append('details', Data.details);
        form_data.append('heading', Data.heading);
        form_data.append('fellowshipYear', Data.fellowshipYear);

        http.put(`/fellows-details/${selectedFellowId}`, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                
                setImageClear(true)
                
                setData({
                    name: '',
                    address:'',
                    details: '',
                    heading: '',
                    image: ''
                })
                notification.showSuccess("Fellow Details uploaded Successfully!!!")
                setIsEditButnClicked(false)
                setDataUpdateFlag(!dataUpdateFlag)

            })
            .catch(err => {
                
                let final_err = err.response.data.message;
                notification.showError(final_err)

            })

    }

    useEffect(() => {
        http.get('/fellows-details', true)
            .then((res) => {

                setImagetest(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log("error", err);
            })
    }, [dataUpdateFlag])


    const handleLogout = (e) => {
        localStorage.clear();
        props.history.push('/');
    }
   const handleX = (e,editor) => {
        const data = editor.getData()
       setData({
           ...Data,
           details:data
       })
        
    }
    const handleY=(e,editor)=>{
        const data=editor.getData()
        setData({
            ...Data,
            heading:data
        })
    }
    const handleViewDetails=(id)=>{
        setSelectedFellowId(id)
    }
    const handleBack=()=>{
        setSelectedFellowDetails([])
        setSelectedFellowId('')
    }
    const handleEditClick=(id)=>{
        setSelectedFellowId(id)
        setIsEditButnClicked(true)
        setData({
            ...Data,
            name:selectedFellowDetails[0].name,
            address:selectedFellowDetails[0].address,
            details:selectedFellowDetails[0].details,
            heading:selectedFellowDetails[0].heading,
            image:selectedFellowDetails[0].image,
            fellowshipYear:selectedFellowDetails[0].fellowshipYear

        })
    }
    const handleDelete=(id)=>{
        setLoading(true)
        http.remove(`/fellows-details/${id}`, true)
        .then((res) => {

            setImagetest(res.data.data)
            setSelectedFellowDetails([])
            setLoading(false)
        })
        .catch(err => {
            console.log("error", err);
        })
    }
    useEffect(()=>{
        setSelectedFellowDetails(Imagetest.filter(data=>data._id===selectedFellowId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedFellowId,Imagetest])
    const handleChangeFellowshipYear=(e)=>{
        setData({
            ...Data,
            fellowshipYear:Number(e.target.value)
        })
    }
  
   
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
                <div className="container">

                    <img src="logo-05.png" alt="tayar-logo" />
                    <h5>DISASTER RISK REDUCTION<br />YOUNG LEADERS FELLOWSHIP</h5>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/admin-dashboard-signupforupdate" className="nav-link">Landing Page
                                <span className="sr-only">(current)</span>
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to="/admin-fellow" className="nav-link">Fellows</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Faqs</a>
                            </li>
                            <li className="nav-item">
                                <button type="submit" onClick={handleLogout} >Logout</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="row">
                <div className="col-sm-2">
                    <div className="d-flex" id="wrapper">
                        {/* Sidebar */}
                        <div className="bg-light border-right" id="sidebar-wrapper">

                            <div className="list-group list-group-flush">
                                <Link to="/admin-fellow-details" className="list-group-item list-group-item-action bg-light">Enter fellow Details</Link>
                                <Link to="/admin-fellow-overall-data" className="list-group-item list-group-item-action bg-light">Overall Fellows Data</Link>

                            </div>
                        </div>
                        {/* /#sidebar-wrapper */}
                    </div>
                </div>
                <div className="col-sm-10">
                    
                    <div className="container">
                        <div className="row" style={{flexDirection:'column'}}>
                        <h2 id="test">Fellows Details</h2>
   
                        {!selectedFellowDetails.length&&
                           <table style={{marginLeft:'0px'}}>
                           <tr>
                                   <th>Serial Number</th>
                                   <th>Name</th>
                                   <th>Image</th>
                                   <th>Fellowship Year</th>
                                   <th>Address</th>
                                  
                                  
                                   <th>Action</th>
                               </tr>
                             {Imagetest&&Imagetest.map((data,i)=>{
                                 return <tr key={data._id}>
                                     <td>{i+1}</td>
                                 <td>{data.name}</td>
                                 <td> <img src={'https://fellowship.youthinnovationlab.org/media/' + data.image} style={{ height: 100, width: 100 }} alt="fellow" /></td>
                                 {/* <td>{data.image}</td> */}
                                 <td>{data.fellowshipYear}</td>
                                 <td>{data.address}</td>
                                
                               
                                 <td><button type="button" onClick={()=>handleViewDetails(data._id)} style={{marginLeft:'5px',marginRight:'5px',borderRadius:'10px',backgroundColor:'blue',color:'white'}} >View</button>
                                 <button type="button" onClick={()=>handleDelete(data._id)} style={{marginLeft:'5px',marginRight:'5px',borderRadius:'10px',backgroundColor:'blue',color:'white'}} >Delete</button>
                                 </td>
                             </tr>
                             })}
                              
                               
                               
                           </table>}
                           {selectedFellowDetails&& !isEditButnClicked&&selectedFellowDetails.map((item)=>{
                              return <div style={{width:'100%',border:'2px solid',padding:'10px'}} key={item._id}>
 
                               <p><strong> Name:{""}</strong>{item.name}</p>
                               <p><strong>Image:{""}</strong><img src={'https://fellowship.youthinnovationlab.org/media/' + item.image} style={{ height: 100, width: 100 }} alt="fellow" /></p>
                               <p><strong>Fellowship Year:{""}</strong>{item.fellowshipYear}</p>
                               <p><strong>Address:{""}</strong>{ReactHtmlParser(item.address)}</p>
                               <p><strong>Heading:{""}</strong>{ReactHtmlParser(item.heading)}</p>
                               <p><strong>Details:{""}</strong>{ReactHtmlParser(item.details)}</p>
                               <button type="button" onClick={handleBack} style={{marginLeft:'5px',marginRight:'5px',borderRadius:'10px',backgroundColor:'blue',color:'white'}}>Back</button>
                               <button type="button" onClick={()=>handleEditClick(item._id)} style={{marginLeft:'5px',marginRight:'5px',borderRadius:'10px',backgroundColor:'blue',color:'white'}} >Edit</button>
                               <button type="button" onClick={()=>handleDelete(item._id)} style={{marginLeft:'5px',marginRight:'5px',borderRadius:'10px',backgroundColor:'blue',color:'white'}} >Delete</button>
                           </div>
                           })}
                           {isEditButnClicked&&<>
                                                <div>
                                                <label for="fellowshipYear">Choose a Fellowship Year:</label>
                          <select name="fellowshipYear" id="fellowshipYear" value={Data.fellowshipYear} onChange={handleChangeFellowshipYear}>
                            <option value="">Select a Fellowship Year</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                           
                          </select>
                          </div>
                                <form role="form" className="col-md-9 go-right">
                              

                               <div className="form-group">
                                   <label htmlFor="name">Name</label>
                                   <input id="name" name="name" placeholder=" Full Name " type="text" className="form-control" value={Data.name} onChange={handleName} />
                               </div>
                                <div className="form-group">
                                   <label htmlFor="address">Address</label>
                                   <input id="name" name="address" type="text" placeholder="Address of Fellow" className="form-control" value={Data.address} onChange={handleAddress} />
                               </div>
                               <div className="form-group">
                                   {/* <label htmlFor="email">Heading</label>
                                   <input id="name" name="heading" type="text" placeholder="Heading of fellow" className="form-control" value={Data.heading} onChange={handleHeading} /> */}
                                    <CKEditor
                                                
                                                       editor ={ClassicEditor}
                                                       onChange={handleY}
                                                      
                                                         data={Data.heading}
                                                       />
                               </div>
                               {/* <div className="form-group">

                                   <textarea id="message" name="details" className="form-control" placeholder="Details of Fellow" style={{ height: 150 }} required value={Data.details} onChange={handleDetails} />
                               </div> */}
                                <CKEditor
                                                
                                                       editor ={ClassicEditor}
                                                       onChange={handleX}
                                                    
                                                         data={Data.details}
                                                       />
                               <div className="form-group">

                                   <input name="image" type="file" placeholder="Photo " className="form-control" onChange={handleImage} />

                               </div>
                               <div className="form-group">

                                   <button type="submit" className="btn btn-primary" onClick={handleUpdate} >Update</button>
                                   <button type="submit" className="btn btn-primary" onClick={()=>setIsEditButnClicked(false)} >Back</button>

                               </div>
                           </form>
                           </>
                           }
                        </div>
                    </div>








                </div>

            </div>
        </div >
    )
}
export default OverallFellowsData;