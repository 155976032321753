import { toast } from 'react-toastify';


function showError(msg) {
    toast.error(msg);
}

function showSuccess(msg) {
    toast.success(msg);
}


function handleError(err) {

    let msg = 'Invalid username or password';

    showError(msg);
}

export default { handleError, showSuccess, showError };