import React, { useEffect, useState } from 'react';
import './Guidelines.css';
import { Link } from 'react-router-dom';
import http from '../Utilities/Utils';
import notification from '../Utilities/Notification';
import Footer from './Footer';
import Header from './Header';
import Table from 'react-bootstrap/Table'

const Guidelines = (props) => {
    const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
    const[scroll,setScroll]=useState(0);
    const [sticky,setSticky]=useState(false);
    const [distanceFromTop,setDistanceFromTop]=useState(0)

    const navbar=React.createRef();

// const handleScroll=(e)=>{
//     if (window.scrollY > distanceFromTop && sticky === false) {
//         setSticky(true)
       
//       } else if (window.scrollY < distanceFromTop && sticky) {
        
//         setSticky(false)
//       }
// }

    // useEffect(()=>{
    //     const distanceFromTop = navbar.current.getBoundingClientRect().top;
    //     setScroll(window.scrollY)
    //     setDistanceFromTop(distanceFromTop)
    //     window.addEventListener("scroll",e=>{
    //         handleScroll(e)
    //     })
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[sticky])
    // const styles = sticky
    // ? {
    //     position: "fixed",
    //     top: "0px"
    //   }
    // : {}
    const handleSubmit = () => {
        window.open(" https://bit.ly/3jD3Kqc", "_blank")
    }

    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }


    const handleDownload = () => {
        window.open("/Application_Guidelines_2021.pdf", "_blank")
    }
    const bipadLink = () => {
        window.open("https://bipadportal.gov.np", "_blank");
    }
    const handleYouth = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6AQD9FV6xudNkjgJQal_ytpURDNFS0VKSUUyNDA3TFNYMVYwS0VCNTFJUi4u", "_blank")
    }
    const [Data, setData] = useState({
        email: ''
    });
    const handleSubmission = (e) => {
        setSignupbottonclicked(true)

        http.post('/signupforupdate/', Data)
            .then(res => {

                notification.showSuccess("Email received, Thank you!!");
                setData({ email: '' })
                setSignupbottonclicked(false)
                props.history.push('/')
            })
            .catch(err => {
                let final_err = err.response.data.email[0];
                notification.showError(final_err)
                setSignupbottonclicked(false)
            })
    }
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }



    return (
        <div>
            <Header />
            <div className="container-fluid content">
                <div className="row ray micro-container-guidelines">
                    <div className="guidelines-image">
                        <h4>Guidelines</h4>
                    </div>

                </div>
                <section className="quesn-catagory1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-1 col-lg-1">
                                <h4>Guidelines</h4>
                            </div>
                            
                            {/* <div className="col-md-11 col-lg-11 guidelines-topics" ref={navbar} style={{ ...styles }}> */}
                            <div className="col-md-11 col-lg-11 guidelines-topics" >
                                <a href="#overview" id="overview1">Overview</a>
                                <a href="#application-sum" id="applicationsum">Application Summary</a>
                                <a href="#eligible" id="elig"> Eligibility & Selection Criteria</a>
                                <a href="#key" id="key-res">Key Responsibilities</a>
                                <a href="#online" id="online-application">Online Application</a>
                                <a href="#reference" id="ref-guidelines"> Reference Guidelines</a>
                                <button className="btn" id="download" onClick={handleDownload} >
                                    Download PDF <img src="dl-icon.svg" alt="dl-icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>



                <div className="row qns">
                    <div className="container-fluid test1">

                        <h4 id="overview">Program Overview  </h4>
                        <p>Disaster Risk Reduction – Young Leaders Fellowship (DRR-Fellowship) provides young, qualified, and enthusiast individuals from 15 project municipalities with an opportunity to gain in-depth experience in disaster information management system. DRR-Fellowship further builds the next generation pool of professionals that will be leading the discussion on DRRM and related discipline</p>
                        <p>DRR-Fellowship by Youth Innovation Lab (YI-Lab) is a USAID’s Tayar Nepal funded project that aims to build technical capacities of local government to support localization of Disaster Information Management System (DIMS) locally known as Building Information Platform Against Disaster (BIPAD) portal. BIPAD portal brings all credible digital and spatial data available within government bodies, non-government organizations, academic and research institutions into a single platform. The portal allows local governments to host BIPAD independently, fostering an effective data partnership mechanism to enhance their preparedness, mitigation, response, and recovery activities. For more on BIPAD portal, visit <a className="linking-bipad" onClick={bipadLink}>https://bipadportal.gov.np.</a></p>



                        <p>Given the reality that most of the municipalities lack capacities to collect, manage and use DRR information for informed decision making, DRR-Fellowship aims to address this issue by mobilizing technically trained youths with sound knowledge on BIPAD portal in the local governments. DRR fellows act as a connecting bridge to transfer the technical capacity of using BIPAD portal to the local governments. Throughout DRR-Fellowship, DRR fellows will also have exciting opportunities to participate in learning and training events being organized by leading organizations in the field of DRRM including NDRRMA, Tayar Nepal, and project municipalities.</p>




                    </div>
                    <div className="container-fluid test">
                        <p>Youth Innovation Lab encourages all the interested applicants, especially youth from project municipalities including those who identify as women and indigenous community members/ minority caste groups in joining the Disaster Risk Reduction – Young Leaders Fellowship 2021 program.  </p>
                        <p>All the applications will be reviewed thoroughly,<b> please follow the given instructions carefully to ensure that qualifications are met and that application components are accurate, complete, and submitted on time.</b></p>
                    </div>
                    <div className="container-fluid test1 ">
                        <h4 id="application-sum">Application Summary</h4>
                        <p>Application must be completed online and consists of the following components:</p>
                        <ol>
                            <li> Personal information </li>
                            <li> Motivation letter (Max. 300 words) </li>
                            <li> CV/Resume (CV no longer than 2 pages) </li>
                            <li> One Reference (Academic recommended) </li>
                        </ol>
                        <p> In preparation for the application process, please secure the followings: </p>
                        <ol>
                            <li> Motivation letter stating your interest and qualification for the fellowship position </li>
                            <li> Your CV/Resume (in pdf) </li>
                            <li> Recommendation letter (in pdf) from your academic institution or workplace</li>
                        </ol>
                    </div>
                    <div className="container-fluid test1">
                        <h4 id="application-sum">Project Municipalities</h4>
                        {/* <table className="tab">
                            <thead>
                                <tr>
                                    <td>Province</td>
                                    <td>District</td>
                                    <td>Municipality</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Province 1</td>
                                    <td>Dhankuta</td>
                                    <td className="mun-name" >Dhankuta Municipality</td>
                                </tr>
                                <tr>
                                    <td>Province 2</td>
                                    <td>Dhanusha</td>
                                    <td className="mun-name">Janakpurdham SMC</td>
                                </tr>
                                <tr>
                                    <td>Bagmati</td>
                                    <td>Dolakha</td>
                                    <td className="mun-name">Jiri Municipality</td>
                                </tr>
                                <tr>
                                    <td>Bagmati </td>
                                    <td>Sindhupalchok</td>
                                    <td className="mun-name">Chautara Sangachokgadhi Municipality</td>
                                </tr>
                                <tr>
                                    <td>Bagmati</td>
                                    <td>Sindhupalchok</td>
                                    <td className="mun-name">Melamchi Municipality</td>
                                </tr>
                                <tr>
                                    <td>Gandaki</td>
                                    <td>Gorkha</td>
                                    <td className="mun-name">Gorkha Municipality</td>
                                </tr>
                                <tr>
                                    <td>Gandaki </td>
                                    <td>Kaski</td>
                                    <td className="mun-name">Pokhara MC</td>
                                </tr>
                                <tr>
                                    <td>Gandaki </td>
                                    <td>Baglung </td>
                                    <td className="mun-name">Baglung Municipality </td>
                                </tr>
                                <tr>
                                    <td>Gandaki</td>
                                    <td>Myagdi</td>
                                    <td className="mun-name">Beni Municipality</td>
                                </tr>
                                <tr>
                                    <td>Lumbani</td>
                                    <td>Dang</td>
                                    <td className="mun-name">Ghorahi SMC</td>
                                </tr>
                                <tr>
                                    <td>Lumbani</td>
                                    <td>Bardiya</td>
                                    <td className="mun-name">Madhuban Municipality</td>
                                </tr>
                                <tr>
                                    <td>Karnali </td>
                                    <td>Surkhet </td>
                                    <td className="mun-name">Gurbakot municipality </td>
                                </tr>
                                <tr>
                                    <td>Karnali</td>
                                    <td>Dailekh</td>
                                    <td className="mun-name">Chamunda Bindrasaini Municipality</td>
                                </tr>
                                <tr>
                                    <td>Sudurpaschim </td>
                                    <td>Kailali</td>
                                    <td className="mun-name">Tikapur Municipality </td>
                                </tr>
                                <tr>
                                    <td>Sudurpaschim </td>
                                    <td>Kanchanpur</td>
                                    <td className="mun-name">Krishnapur Municipality</td>
                                </tr>
                            </tbody>
                        </table> */}
                        <Table responsive striped bordered hover >
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#172845", color: "#ffffff", borderColor: "#172845" }}>Province</th>
                                    <th style={{ backgroundColor: "#172845", color: "#ffffff", borderColor: "#172845" }}>District</th>
                                    <th style={{ backgroundColor: "#172845", color: "#ffffff", borderColor: "#172845" }}>Municipality</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Province 1</td>
                                    <td>Dhankuta</td>
                                    <td className="mun-name" >Dhankuta Municipality</td>
                                </tr>
                                <tr>
                                    <td>Province 2</td>
                                    <td>Dhanusha</td>
                                    <td className="mun-name">Janakpurdham SMC</td>
                                </tr>
                                <tr>
                                    <td>Bagmati</td>
                                    <td>Dolakha</td>
                                    <td className="mun-name">Jiri Municipality</td>
                                </tr>
                                <tr>
                                    <td>Bagmati </td>
                                    <td>Sindhupalchok</td>
                                    <td className="mun-name">Chautara Sangachokgadhi Municipality</td>
                                </tr>
                                <tr>
                                    <td>Bagmati</td>
                                    <td>Sindhupalchok</td>
                                    <td className="mun-name">Melamchi Municipality</td>
                                </tr>
                                <tr>
                                    <td>Gandaki</td>
                                    <td>Gorkha</td>
                                    <td className="mun-name">Gorkha Municipality</td>
                                </tr>
                                <tr>
                                    <td>Gandaki </td>
                                    <td>Kaski</td>
                                    <td className="mun-name">Pokhara MC</td>
                                </tr>
                                <tr>
                                    <td>Gandaki </td>
                                    <td>Baglung </td>
                                    <td className="mun-name">Baglung Municipality </td>
                                </tr>
                                <tr>
                                    <td>Gandaki</td>
                                    <td>Myagdi</td>
                                    <td className="mun-name">Beni Municipality</td>
                                </tr>
                                <tr>
                                    <td>Lumbini</td>
                                    <td>Dang</td>
                                    <td className="mun-name">Ghorahi SMC</td>
                                </tr>
                                <tr>
                                    <td>Lumbini</td>
                                    <td>Bardiya</td>
                                    <td className="mun-name">Madhuban Municipality</td>
                                </tr>
                                <tr>
                                    <td>Karnali </td>
                                    <td>Surkhet </td>
                                    <td className="mun-name">Gurbakot municipality </td>
                                </tr>
                                <tr>
                                    <td>Karnali</td>
                                    <td>Dailekh</td>
                                    <td className="mun-name">Chamunda Bindrasaini Municipality</td>
                                </tr>
                                <tr>
                                    <td>Sudurpaschim </td>
                                    <td>Kailali</td>
                                    <td className="mun-name">Tikapur Municipality </td>
                                </tr>
                                <tr>
                                    <td>Sudurpaschim </td>
                                    <td>Kanchanpur</td>
                                    <td className="mun-name">Krishnapur Municipality</td>
                                </tr>

                            </tbody>
                        </Table>
                    </div>

                    <div className="container-fluid test1">
                        <h4 id="application-sum">Project Duration</h4>
                        <p>Six months </p>
                    </div>

                    <div className="container-fluid test1 ">
                        <h4 id="eligible">Eligibility and Selection Criteria </h4>
                        <p>DRR – Young Leaders Fellows must: </p>
                        <ol>
                            <li> Be local from the project municipalities or willing to relocate to the project municipality.</li>
                            <li> Be of 30 years old or younger at the nomination.</li>
                            <li> Have a bachelor’s degree in Environmental Science, Environmental Engineering, Geomatics Engineering, Disaster Risk Reduction, Development Studies, Natural Resources, Crisis Management, Computer Science, Computer Engineering, Information Technology or related field</li>

                            <li> Have an interest in learning technology and open data. </li>
                            <li> Have knowledge of or interest in disaster/DRRM and related discipline</li>
                            <li> Be curious to work with municipality and Information Technology (IT) officers . </li>
                            <li> Have strong knowledge in technology that includes mobile app, web app, MS office package.</li>
                            <li> Have excellent communication skills in Nepali and English. Knowledge of the local language would be preferred. </li>
                            <li>Have outstanding personal character and integrity.</li>
                            <li> Have teamwork and collaborative skills.</li>
                        </ol>

                    </div>
                    <div className="container-fluid test1">
                        <h4 id="key">Key Responsibilities of the DRR Fellows </h4>
                        <p>DRR fellows will be responsible for:</p>
                        <ol>
                            <li> Supporting their assigned municipality to administer and operationalize municipal level BIPAD portal.</li>
                            <li> Collaborating with the IT officer and municipal DRR focal personnel for day-to-day capacity transfer.</li>
                            <li> Working closely with the municipal personnel to compile DRR related datasets and standardize and integrate them into BIPAD.</li>
                            <li>Working with municipal personnel to map and validate critical infrastructure datasets like education, health, finance, governance, tourism, industry, communication, and more.</li>
                            <li>Reporting and documenting the progress/challenges and share the case studies</li>
                        </ol>

                    </div>
                    <div className="container-fluid test1">
                        <h4 >Benefits for the DRR Fellows</h4>
                        <ol>
                            <li> Five-day residential training on DIMS and DRRM.</li>
                            <li> Stipend NRP 30,000 per month during the course of DRR-Fellowship.</li>
                            <li> Opportunity to work closely with Mayor, CAO, IT professionals, and Government officials at working station.</li>
                            <li> Opportunity to participate in workshops/trainings/seminars organized by NDRRMA, Tayar Nepal, and respective municipality.  </li>
                            <li> Graduation Certificate of the fellowship. </li>
                            <li> Potential job placement within YI-Lab, partners, and related organizations.</li>
                        </ol>


                    </div>
                    <div className="container-fluid test1">
                        <h4 id="online">Online Application</h4>
                        <p>Applications for the DRR–Fellowship are to be submitted by completing the Online For <a id="online-form" onClick={handleSubmit}>Online Form</a>. The online form has two main sections: </p>

                        <ol>
                            <li> Personal Information – Provide personal information such as contact details and your educational and professional background and upload your picture (Passport size photo, no larger than 2 MB).</li>
                            <li> Written section – Write about your motivation to apply for DRR-Fellowship and upload CV/Resume and a recommendation letter. </li>
                        </ol>


                    </div>
                    <div className="container-fluid test1">
                        <h4 id="reference">Guidelines for Reference</h4>
                        <p>
                            While requesting a recommendation letter, you may find it useful to forward the following guidelines directly to your reference. The letter could be written either in English or in Nepali. Remember to ask for the recommendation letter in advance so that you have enough time to upload in the online application.  </p>
                        <ol>
                            <li> The length of time and context in which they have known the applicant.</li>
                            <li> Their opinion of the applicant’s background, qualifications, accomplishments, and experiences</li>
                            <li> An assessment of the applicant’s leadership ability and potential.  </li>
                        </ol>
                        <p>It is strongly recommended that reference: </p>
                        <ol>
                            <li> Draft their letter in the appropriate file format: MS Word Document or .pdf </li>
                            <li> Sign their name, official position, and contact information.</li>
                        </ol>
                    </div>
                    <div className="container-fluid selection ">
                        <h4>Application Timeline</h4>
                        <p>August 25, 2021 – Application opens</p>
                        <p>September 13, 2021 (5 pm) – Application deadline.</p>
                    </div>
                    <div className="container-fluid test1">
                        <h4>Contact </h4>
                        <p>For questions and clarifications on the application process, please contact:  </p>
                        <p>Fellowship Secretariat  </p>
                        <p>Fellowship@youthinnovationlab.org </p>
                    </div>
                </div>
                < Footer />
            </div>
        </div >

    )
}

export default Guidelines;