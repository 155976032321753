import axios from 'axios';



const BASE_URL = process.env.REACT_APP_BASE_URL
// const BASE_URL='http://127.0.0.1:8000/api/v1'


const https = axios.create({
    baseURL: BASE_URL,
    responseType: "json"
})

function getHeaders(secure = false) {
    let headers;
    if (secure) {
        headers = {
            'content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }
    }
    else {
        headers = {
            'content-Type': 'application/json'
        }
    }
    return headers;
}
function post(url, data, isSecure) {
    return https.post(
        url, data,
        {
            headers: getHeaders(isSecure)
        }
    )
}
function remove(url, isSecure) {
    return https.delete(
        url, { headers: getHeaders(isSecure) }
    )
}
function put(url, data, isSecure) {
    return https.put(
        url, data,
        {
            headers: getHeaders(isSecure)
        }
    )
}
function get(url, issecure) {
    return https.get(url, {
        headers: getHeaders(issecure)
    })
}

export default { post, get,remove,put };