import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./../PageContainer/Fellows.css";
import http from "../Utilities/Utils";
import notification from "../Utilities/Notification";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Header from "./Header";
import Footer from "./Footer";

const Fellows = (props) => {
  const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
  const [fellowDetailsByYear, setFellowDetailsByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [fellowshipYear, setFellowshipYear] = useState([]);
  const [fellowByYear, setFellowsByYear] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const handleSubmit = () => {
    window.open(
      " https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6PvmnTPPcHlNj7sJ0nzSNLxUNUJPSUZOTDg2SFVBS0hETTRRWk8yT1hITC4u ",
      "_blank"
    );
  };
  const handleYilab = () => {
    window.open("https://youthinnovationlab.org", "_blank");
  };
  const handleDisaster = () => {
    window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank");
  };

  const [Data, setData] = useState({
    email: "",
  });
  const handleSubmission = (e) => {
    setSignupbottonclicked(true);
    http
      .post("/signupforupdates/", Data)
      .then((res) => {
        notification.showSuccess("Email received, Thank you!!");
        setData({ email: "" });
        setSignupbottonclicked(false);
        props.history.push("/");
      })
      .catch((err) => {
        let final_err = err.response.data.email[0];
        notification.showError(final_err);
        setSignupbottonclicked(false);
      });
  };
  const handleChange = (e) => {
    setData({ email: e.target.value });
  };
  let screen = window.screen.width;
  const handleClicking = () => {
    let x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };
  const [FellowDetails, setFellowDetails] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    http
      .get("/fellowsdetails/", true)
      .then((res) => {
        setLoading(false);
        setFellowDetails(res.data.results);
      })
      .catch((err) => {
        console.log("error", err);
      });
    http
      .get("/fellowshipYear/", true)
      .then((res) => {
        setLoading(false);
        setFellowshipYear(res.data.results);
      })
      .catch((err) => {
        console.log("error", err);
      });
    http
      .get("/fellowshipproject/", true)
      .then((res) => {
        setLoading(false);
        setProjectList(res.data.results);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  useEffect(() => {
    if (fellowshipYear.length) {
      setSelectedYear(fellowshipYear[0].fellowship_year);
      const selectedFinalProject = fellowshipYear[0].projects[0];

      setSelectedProject(selectedFinalProject);
    }
  }, [fellowshipYear, projectList]);
  useEffect(() => {
    const FellowsDetailsByYear = FellowDetails.map((item) => {
      const fellowship = fellowshipYear
        .filter((data) => data.id === item.fellowship)
        .map((data) => data.fellowship_year);
      return {
        ...item,
        fellowship_year: fellowship[0],
      };
    });
    setFellowsByYear(FellowsDetailsByYear);
  }, [FellowDetails, fellowshipYear]);

  useEffect(() => {
    setLoading(true);
    setFellowDetailsByYear(
      fellowByYear
        .filter((data) => data.fellowship_year === selectedYear)
        .filter((d) => d.project === selectedProject)
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellowByYear, selectedYear, selectedProject]);

  const filterFellowsByProject = (id) => {
    setSelectedProject(id);
    const data = fellowDetailsByYear.filter((d) => d.project === id);
    setFellowDetailsByYear(data);
  };

  const ProjectIdToNameConverter = (id) => {
    const data = projectList.length
      ? projectList.find((d) => d.id === id).fellowship_project
      : "";
    return data;
  };

  return (
    <div>
      <Header />
      <div className="container-fluid content">
        <div className="row ray-fellows fellowsCoverPicContainer">
          <div className="fellows-image">
            <h4>Meet Our Fellows</h4>
          </div>
        </div>
        {/* <div className="row blog-soon">
                    <p> Fellows Details will be Uploaded Soon...</p>
                </div> */}
        <div className="row fellow-row">
          <div className="col-sm-4 col-lg-3 fellows-year">
            <h4 style={{ fontSize: "20px" }}>DRR Fellows</h4>
            {fellowshipYear.map((item) => (
              <div key={item.id}>
                <p
                  style={{ cursor: "pointer" }}
                  // className={
                  //   selectedYear === item.fellowship_year ? "selectedYear" : ""
                  // }
                  onClick={() => setSelectedYear(item.fellowship_year)}
                >
                  {item.fellowship_year}
                  <ul>
                    {item.projects.length
                      ? item.projects.map((list) => {
                          return (
                            <li
                              key={list}
                              className={
                                selectedProject === list &&
                                selectedYear === item.fellowship_year
                                  ? "selectedYear"
                                  : ""
                              }
                              onClick={() => filterFellowsByProject(list)}
                            >
                              {ProjectIdToNameConverter(list)}
                            </li>
                          );
                        })
                      : ""}{" "}
                  </ul>
                </p>
              </div>
            ))}
            {/* <p
              style={{ cursor: "pointer" }}
              className={selectedYear === 2021 ? "selectedYear" : ""}
              onClick={() => setSelectedYear(2021)}
            >
              2021{" "}
            </p>
            <p
              style={{ cursor: "pointer" }}
              className={selectedYear === 2020 ? "selectedYear" : ""}
              onClick={() => setSelectedYear(2020)}
            >
              {" "}
              2020{" "}
            </p> */}
            <hr />
          </div>
          <div
            className="col-sm-8 col-lg-9 colm-right"
            style={
              !fellowDetailsByYear.length
                ? { display: "flex", alignItems: "center" }
                : { display: "flex", flexDirection: "column" }
            }
          >
            {Loading ? (
              <p>Loading</p>
            ) : !fellowDetailsByYear.length ? (
              <p>
                No Data available of fellows for fellowship year {selectedYear}{" "}
              </p>
            ) : (
              fellowDetailsByYear.map((item, id) => {
                return (
                  <div key={id}>
                    <div className="fellows">
                      <Link
                        to={{
                          pathname: "/fellows-details",
                          state: {
                            name: item.name,
                            address: item.address,
                            heading: item.heading,
                            details: item.details,
                            image: item.image,
                          },
                        }}
                      >
                        <img
                          src={item.image}
                          style={{ height: 100, width: 100 }}
                          alt="fellow"
                        />
                      </Link>

                      <div className="det">
                        <h4>{item.name}</h4>
                        <h6>{item.address}</h6>
                        {ReactHtmlParser(item.heading)}
                        <Link
                          to={{
                            pathname: "/fellows-details",
                            state: {
                              name: item.name,
                              address: item.address,
                              heading: item.heading,
                              details: item.details,
                              image: item.image,
                            },
                          }}
                        >
                          READ MORE
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Fellows;
