import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Routing from './Utilities/Routing';
import { ToastContainer } from 'react-toastify';







function App() {
  return (
    <>
      <Routing />
      <ToastContainer />
    </>
  );
}

export default App;
