import React from 'react';
import './Header.css'
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';


const Header = () => {
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <div className="container-fluid nav-wrapper">
                    <div className="logo-link">
                        < img src="/logo-05.png" alt="logo" />
                        <Link className="nav-link" id="nav-left" to="/">DISASTER RISK REDUCTION<br /> YOUNG LEADERS FELLOWSHIP</Link>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Link to="/guidelines" className="nav-link" id="nav-right ">Guidelines</Link>
                            <Link className="nav-link" id="nav-right" to="/blog-listing">Blogs</Link>
                            <Link to="/fellows" className="nav-link" id="nav-right">Fellows</Link>
                            <Link to="/faqs" className="nav-link" id="nav-right">FAQs </Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    )
}
export default Header;