import React, { useEffect, useState } from 'react';
import './Blog.css';
import { Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import http from '../Utilities/Utils';
import notification from '../Utilities/Notification';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import { render } from '@testing-library/react';
import Header from './Header';
import Footer from './Footer';
const Blogs = (props) => {

    const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
    const [selectedFellowBlog, setSelectedFellowBlog] = useState([])
    const [selectedFellow, setSelectedFellow] = useState()
    const [blogVideo, setBlogVideo] = useState('')
    const [overallBlogsData, setOverallBlogsData] = useState([])
    const [LoadingBlogs, setLoadingBlogs] = useState(true)
    const [show, setShow] = useState(false)
    const [blogImageId, setBlogImageId] = useState(null)

    const { id } = useParams()
    const handleSubmit = () => {
        window.open(" https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6PvmnTPPcHlNj7sJ0nzSNLxUNUJPSUZOTDg2SFVBS0hETTRRWk8yT1hITC4u ", "_blank")
    }

    const handleYouth = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6AQD9FV6xudNkjgJQal_ytpURDNFS0VKSUUyNDA3TFNYMVYwS0VCNTFJUi4u", "_blank")
    }

    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }
    const [Data, setData] = useState({
        email: ''
    });
    const handleSubmission = (e) => {
        setSignupbottonclicked(true)

        http.post('/signupforupdates/', Data)
            .then(res => {

                notification.showSuccess("Email received, Thank you!!");
                setData({ email: '' })
                setSignupbottonclicked(false)
                props.history.push('/')
            })
            .catch(err => {

                let final_err = err.response.data.email[0];
                notification.showError(final_err)
                setSignupbottonclicked(false)
            })
    }
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }
    useEffect(() => {
        http.get(`/blogs/${id}/`, true)
            .then(res => {
                setSelectedFellowBlog([res.data])
                setBlogVideo(res.data.videos)
                setLoadingBlogs(false)
            })
            .catch(err => {
                console.log('This is error', err.response)
            })
        http.get(`/blogs/`, true)
            .then(res => {
                setOverallBlogsData(res.data.results)


            })
            .catch(err => {
                console.log('This is error', err.response)
            })
    }, [id])
    useEffect(() => {
        if (selectedFellowBlog.length) {
            http.get(`fellowsdetails/${selectedFellowBlog[0].fellow_name}/`)
                .then(res => {
                    setSelectedFellow(res.data)
                })
                .catch(err => {
                    console.log("err.", err.response)
                })
        }

    }, [selectedFellowBlog])

    const handleSelectBlog = (data) => {
        http.get(`/blogs/${data}/`, true)
            .then(res => {
                setSelectedFellowBlog([res.data])
                setBlogVideo(res.data.videos)
                window.scrollTo(0, 0)
            })
            .catch(err => {
                console.log('This is error', err.response)
            })
    }



    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setBlogImageId(id)
    }
    const selectedImageModal = selectedFellowBlog.length && selectedFellowBlog[0].image.filter((item) => item.id === blogImageId)




    return (
        <div>
            <Header />
            <div className="container-fluid content">
                <div className="row ray micro-container-guideliness">
                    <div className="guidelines-image">
                        <h4>Blogs</h4>
                    </div>
                </div>
            </div>
            {LoadingBlogs ? <div style={{ minHeight: '208px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p >Loading Blogs,Please Wait...</p></div> :
                <div>
                    <section className="blog-author">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 author-description">
                                    <div className="author-detail">
                                        <div className="blog-author-img">
                                            <img src={selectedFellow && selectedFellow.image} alt="fellow" />
                                        </div>
                                        <div className="blog-author-info">
                                            <p className="author-name">{selectedFellow && selectedFellow.name} </p>
                                            <div className="blog-author-date">
                                                <p>{selectedFellow && selectedFellow.address.split(',')[0]} | {selectedFellow && selectedFellow.address.split(',').pop()}</p>
                                                <p>{selectedFellowBlog.length && selectedFellowBlog[0].date_created}</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="author-svg">
                                        <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.8" clipPath="url(#clip0)">
                                                <path d="M0.838938 26.9262C0.838938 26.9304 0.838479 26.9346 0.838479 26.9388C0.838479 32.0427 4.97574 36.1802 10.0794 36.1802C15.1831 36.1802 19.3203 32.0428 19.3203 26.9388C19.3203 21.8348 15.1827 17.6974 10.0794 17.6974C9.03047 17.6974 8.02643 17.8803 7.08696 18.2024C9.16602 6.2749 18.4668 -1.41712 9.84508 4.91358C0.284808 11.9336 0.828241 26.6568 0.838938 26.9262Z" fill="#172845" fillOpacity="0.4" />
                                                <path d="M30.4092 17.6975C29.3603 17.6975 28.3563 17.8803 27.4163 18.2025C29.4959 6.27499 38.7967 -1.41703 30.1749 4.91367C20.6146 11.9336 21.158 26.6568 21.1688 26.9262C21.1688 26.9304 21.1683 26.9346 21.1683 26.9388C21.1683 32.0427 25.3055 36.1802 30.4092 36.1802C35.513 36.1802 39.6501 32.0428 39.6501 26.9388C39.6501 21.8348 35.5125 17.6975 30.4092 17.6975Z" fill="#172845" fillOpacity="0.4" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="38.82" height="38.8218" fill="white" transform="translate(39.6501 38.8218) rotate(-180)" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </div>
                                    <p className="author-quote">{selectedFellowBlog.length && (selectedFellowBlog[0].quotes1)}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </section>
                    <section className="blog-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 blog-recent-article-detail">
                                    <h1>{selectedFellowBlog.length && selectedFellowBlog[0].heading1}</h1>
                                    {ReactHtmlParser(selectedFellowBlog.length && selectedFellowBlog[0].blogIntroduction)}
                                    <img id="intro-image" src={selectedFellowBlog.length && selectedFellowBlog[0].blogIntroduction_image} alt="yilab" />
                                    {selectedFellowBlog[0].image[0].photo_credit && <p className="photo-credit">{selectedFellowBlog[0].blogIntroduction_image_caption}</p>}
                                    {ReactHtmlParser(selectedFellowBlog.length && selectedFellowBlog[0].blogIntroduction_second_par)}

                                </div>
                            </div>
                        </div>
                    </section>
                    {selectedFellowBlog.length && selectedFellowBlog[0].videos !== null &&
                        <section className="video">
                            <div className="container-fluid video-background">
                                {selectedFellowBlog.length && selectedFellowBlog.map((item) => {
                                    return <video width="900" controls key={item.id}>
                                        <source src={item.videos} type="video/mp4"></source>
                                    </video>
                                })}


                            </div>
                        </section>}

                    <section style={{ paddingBottom: '5%' }} className="blog-content-2nd">
                        <div className="container">
                            <div className="col-lg-12 col-md-12 blog-recent-article-detail">
                                <h1>{selectedFellowBlog.length && selectedFellowBlog[0].heading2}</h1>
                                {ReactHtmlParser(selectedFellowBlog.length && selectedFellowBlog[0].blogDetails)}

                                <div className="blog-recent-article-detail-image-2nd">
                                    {selectedFellowBlog.length && selectedFellowBlog[0].image.map((item) => {
                                        return (<div key={item.id} className="blog-images" >
                                            <img src={item.photo} onClick={() => handleShow(item.id)} alt="blog" />
                                            <Modal size="xl"

                                                show={show} onHide={handleClose} >


                                                <Modal.Body><img src={selectedImageModal.length && selectedImageModal[0].photo} alt="fellowship" />
                                                    {selectedImageModal.length && selectedImageModal[0].photo_credit && <p> {selectedImageModal.length && selectedImageModal[0].photo_credit}</p>}
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                        )

                                    }
                                    )
                                    }

                                </div>
                                <div className="design-quote">
                                    <div className="design-quote-wrapper">
                                        <div className="author-svg">
                                            <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.8" clipPath="url(#clip0)">
                                                    <path d="M0.838938 26.9262C0.838938 26.9304 0.838479 26.9346 0.838479 26.9388C0.838479 32.0427 4.97574 36.1802 10.0794 36.1802C15.1831 36.1802 19.3203 32.0428 19.3203 26.9388C19.3203 21.8348 15.1827 17.6974 10.0794 17.6974C9.03047 17.6974 8.02643 17.8803 7.08696 18.2024C9.16602 6.2749 18.4668 -1.41712 9.84508 4.91358C0.284808 11.9336 0.828241 26.6568 0.838938 26.9262Z" fill="#172845" fillOpacity="0.4" />
                                                    <path d="M30.4092 17.6975C29.3603 17.6975 28.3563 17.8803 27.4163 18.2025C29.4959 6.27499 38.7967 -1.41703 30.1749 4.91367C20.6146 11.9336 21.158 26.6568 21.1688 26.9262C21.1688 26.9304 21.1683 26.9346 21.1683 26.9388C21.1683 32.0427 25.3055 36.1802 30.4092 36.1802C35.513 36.1802 39.6501 32.0428 39.6501 26.9388C39.6501 21.8348 35.5125 17.6975 30.4092 17.6975Z" fill="#172845" fillOpacity="0.4" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="38.82" height="38.8218" fill="white" transform="translate(39.6501 38.8218) rotate(-180)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </div>
                                        <p className="author-quote">{selectedFellowBlog.length && (selectedFellowBlog[0].quotes2)}
                                        </p>
                                    </div>
                                </div>

                                {ReactHtmlParser(selectedFellowBlog.length && selectedFellowBlog[0].blogEndParagraph)}
                            </div>
                        </div>
                    </section>
                    <section className="recent-article">
                        <div className="container-fluid">
                            <h3 style={{ marginBottom: '30px' }}>Recommended Reading</h3>
                            <div className="row">
                                {overallBlogsData.length === 1 ?
                                    <div className="col-lg-4 col-md-4 blog-recent">

                                        <div className="blog-recent-article">
                                            <img src={overallBlogsData[0].blogIntroduction_image} alt="fellowship" />
                                            <div className="blog-recent-article-content">
                                                <p>{overallBlogsData[0].title}</p>
                                                <a onClick={() => handleSelectBlog(overallBlogsData[0].id)} >READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    : overallBlogsData.length === 2
                                        ? <>
                                            <div className="col-lg-4 col-md-4 blog-recent">

                                                <div className="blog-recent-article">
                                                    <img src={overallBlogsData[0].blogIntroduction_image} alt="fellowship" />
                                                    <div className="blog-recent-article-content">
                                                        <p>{overallBlogsData[0].title}</p>
                                                        <a onClick={() => handleSelectBlog(overallBlogsData[0].id)} >READ MORE</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 blog-recent">
                                                <div className="blog-recent-article">
                                                    <img src={overallBlogsData[1].blogIntroduction_image} alt="fellowship" />
                                                    <div className="blog-recent-article-content">
                                                        <p>{overallBlogsData[1].title}</p>
                                                        <a onClick={() => handleSelectBlog(overallBlogsData[1].id)}>READ MORE</a>
                                                    </div>
                                                </div>
                                            </div></>
                                        : overallBlogsData.length > 2
                                            ? <>
                                                <div className="col-lg-4 col-md-4 blog-recent">

                                                    <div className="blog-recent-article">
                                                        <img src={overallBlogsData[0].blogIntroduction_image} alt="fellowship" />
                                                        <div className="blog-recent-article-content">
                                                            <p>{overallBlogsData[0].title}</p>
                                                            <a onClick={() => handleSelectBlog(overallBlogsData[0].id)} >READ MORE <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 blog-recent">
                                                    <div className="blog-recent-article">
                                                        <img src={overallBlogsData[1].blogIntroduction_image} alt="fellowship" />
                                                        <div className="blog-recent-article-content">
                                                            <p>{overallBlogsData[1].title}</p>
                                                            <a onClick={() => handleSelectBlog(overallBlogsData[1].id)}>READ MORE <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 blog-recent">
                                                    <div className="blog-recent-article">
                                                        <img src={overallBlogsData[2].blogIntroduction_image} alt="fellowship" />
                                                        <div className="blog-recent-article-content">
                                                            <p>{overallBlogsData[2].title}</p>
                                                            <a onClick={() => handleSelectBlog(overallBlogsData[2].id)}>READ MORE<i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                        </div>

                                                    </div>


                                                </div></>
                                            : null}



                            </div>
                        </div>
                    </section>
                </div>}

            <Footer />
        </div >
    )

}
export default Blogs;

