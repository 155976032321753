import React, { useEffect, useState } from 'react';
import './BlogListings.css';
import { Link } from 'react-router-dom';
import http from '../Utilities/Utils';
import notification from '../Utilities/Notification';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import Header from './Header';
import Footer from './Footer';
const BlogListing = (props) => {

    const [Signupbuttonclicked, setSignupbottonclicked] = useState(false);
    const [blogList, setBlogList] = useState([])
    const [LoadingBlogs, setLoadingBlogs] = useState(true)
    const [blogPostingDate, setBlogPostingDate] = useState('')
    const handleSubmit = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6PvmnTPPcHlNj7sJ0nzSNLxUNUJPSUZOTDg2SFVBS0hETTRRWk8yT1hITC4u ", "_blank")
    }

    const handleYouth = () => {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=L7egsresXka9QU2aRzmF6AQD9FV6xudNkjgJQal_ytpURDNFS0VKSUUyNDA3TFNYMVYwS0VCNTFJUi4u", "_blank")
    }

    const handleYilab = () => {
        window.open("https://youthinnovationlab.org", "_blank")
    }
    const handleDisaster = () => {
        window.open("https://youthinnovationlab.org/Initiatives/drr-ylf", "_blank")
    }
    const [Data, setData] = useState({
        email: ''
    });
    const handleSubmission = (e) => {
        setSignupbottonclicked(true)

        http.post('/signupforupdates/', Data)
            .then(res => {

                notification.showSuccess("Email received, Thank you!!");
                setData({ email: '' })
                setSignupbottonclicked(false)
                props.history.push('/')
            })
            .catch(err => {

                let final_err = err.response.data.email[0];
                notification.showError(final_err)
                setSignupbottonclicked(false)
            })
    }
    const handleChange = (e) => {
        setData({ email: e.target.value })
    }
    let screen = window.screen.width;
    const handleClicking = () => {
        let x = document.getElementById("myLinks")
        if (x.style.display === "block") {
            x.style.display = "none"
        }
        else {
            x.style.display = "block"
        }
    }
    useEffect(() => {
        http.get('/blogs/', true)
            .then(res => {
                setBlogList(res.data.results)
                setLoadingBlogs(false)
            })
            .catch(err => {
                console.log("error", err.response)
            })
    }, [])

    const dateConversion = (date) => {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        const date_data = new Date(date)
        const realDateFormat = months[date_data.getMonth()] + " " + date_data.getDate() + ',' + " " + date_data.getFullYear()
        return realDateFormat

    }


    console.log("this blog list", blogList)
    return (
        <div>
            <Header />
            <div className="container-fluid content">
                <div className="row ray micro-container-guideliness">
                    <div className="guidelines-image">
                        <h4>Blogs</h4>
                    </div>
                </div>
            </div>
            <section className="blog-list">
                <div className="container-fluid">
                    {LoadingBlogs ? <p style={{ textAlign: 'center' }} >Loading Blogs...Please Wait</p> :
                        blogList.length ?
                            <div className="row blog-row">
                                {blogList && blogList.map((item) => {
                                    return <div className="col-lg-4 col-md-4" key={item.id}>

                                        <div className="blog-box" >
                                            <img className="" src={item.blogIntroduction_image} alt="yilab" />
                                            <div className="blog-tag">

                                                <p> {dateConversion(item.date_created)}</p>
                                            </div>
                                            <div className="blog-list-content">
                                                <h1>{item.title}</h1>
                                                {/* <p>{ReactHtmlParser(item.description)}</p> */}
                                                <p>{item.title_description}</p>
                                            </div>
                                            <Link to={{
                                                pathname: `/blogs/${item.id}`,
                                                state: {
                                                    id: item.id
                                                }
                                            }}  ><a className="readmore-button">READ MORE<i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                            </Link>
                                        </div>


                                    </div>
                                })}
                            </div> :
                            <p style={{ textAlign: 'center' }} >Blogs Unavailable...</p>
                    }
                </div>
            </section>
            <Footer />
        </div>




    )
}

export default BlogListing;