import React, { useState, useEffect } from 'react';
import http from '../Utilities/Utils';
import { Link } from 'react-router-dom';
import './FellowData.css';
import notification from './../Utilities/Notification';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FellowsData = (props) => {
    const [Loading, setLoading] = useState(true);
    const [Imagetest, setImagetest] = useState([]);
    const[Value,setValue]=useState('');
    const [Data, setData] = useState({
        name: '',
        address:'',
        details: '',
        heading: '',
        image: '',
        fellowshipYear:null
    })
    const handleName = (e) => {
        setData({
            ...Data,
            name: e.target.value
        })
    }
    const handleAddress=(e)=>{
        setData({
            ...Data,
            address:e.target.value
        })
    }
    const handleDetails = (e) => {
        setData({
            ...Data,
            details: e.target.value
        })
    }
    const handleHeading = (e) => {
        setData({
            ...Data,
            heading: e.target.value
        })
    }
    const handleImage = (e) => {
        setData({
            ...Data,
            image: e.target.files[0]
        })
       
    }
    const [imageclear, setImageClear] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append('image', Data.image);
        form_data.append('name', Data.name);
        form_data.append('address',Data.address);
        form_data.append('details', Data.details);
        form_data.append('heading', Data.heading);
        form_data.append('fellowshipYear', Data.fellowshipYear);

        http.post('/fellows-details', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
   
                setImageClear(true)
                setData({
                    name: '',
                    address:'',
                    details: '',
                    heading: '',
                    image: '',
                    fellowshipYear:''
                })
               
                notification.showSuccess("Fellow Details uploaded Successfully!!!")

            })
            .catch(err => {
               
                let final_err = err.response.data.message;
                notification.showError(final_err)

            })

    }

    useEffect(() => {
        http.get('/fellows-details', true)
            .then((res) => {

                setImagetest(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log("error", err);
            })
    }, [])


    const handleLogout = (e) => {
        localStorage.clear();
        props.history.push('/');
    }
   const handleX = (e,editor) => {
        const data = editor.getData()
       setData({
           ...Data,
           details:data
       })
        
    }
    const handleY=(e,editor)=>{
        const data=editor.getData()
        setData({
            ...Data,
            heading:data
        })
    }
    const handleChangeFellowshipYear=(e)=>{
        setData({
            ...Data,
            fellowshipYear:Number(e.target.value)
        })
    }
    
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
                <div className="container">

                    <img src="logo-05.png" alt="tayar-logo" />
                    <h5>DISASTER RISK REDUCTION<br />YOUNG LEADERS FELLOWSHIP</h5>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/admin-dashboard-signupforupdate" className="nav-link">Landing Page
                                <span className="sr-only">(current)</span>
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to="/admin-fellow" className="nav-link">Fellows</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Faqs</a>
                            </li>
                            <li className="nav-item">
                                <button type="submit" onClick={handleLogout} >Logout</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="row">
                <div className="col-sm-3">
                    <div className="d-flex" id="wrapper">
                        {/* Sidebar */}
                        <div className="bg-light border-right" id="sidebar-wrapper">

                            <div className="list-group list-group-flush">
                                <Link to="/admin-fellow-details" className="list-group-item list-group-item-action bg-light">Enter fellow Details</Link>
                                <Link to="/admin-fellow-overall-data" className="list-group-item list-group-item-action bg-light">Overall Fellows Data</Link>

                            </div>
                        </div>
                        {/* /#sidebar-wrapper */}
                    </div>
                </div>
                <div className="col-sm-9">
                    {/* <form >
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Name" name="name" onChange={handleName} />
                            <textarea style={{ height: 150 }} required type="text" className="form-control" placeholder="details" name="details" onChange={handleDetails} />
                            <textarea style={{ height: 150 }} required type="text" className="form-control" placeholder="details" name="heading" onChange={handleHeading} />
                            <input required type="file" className="form-control-file" name="image" onChange={handleImage} />
                            <input type="submit" defaultValue="Get me the stats!" className="btn btn-default" onClick={handleSubmit} />
                        </div>
                    </form> */}
                    <div className="container">
                        <div className="row" style={{flexDirection:'column'}}>
                        <h2 id="test">Enter Fellows Details</h2>
                        <div>
                        <label for="fellowshipYear">Choose a Fellowship Year:</label>
  <select name="fellowshipYear" id="fellowshipYear" onChange={handleChangeFellowshipYear}>
    <option value="">Select a Fellowship Year</option>
    <option value="2021">2021</option>
    <option value="2020">2020</option>
   
  </select>
  </div>
                            <form role="form" className="col-md-9 go-right">
                                
 
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input id="name" name="name" placeholder=" Full Name " type="text" className="form-control" value={Data.name} onChange={handleName} />
                                </div>
                                 <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input id="name" name="address" type="text" placeholder="Address of Fellow" className="form-control" value={Data.address} onChange={handleAddress} />
                                </div>
 
                              
  
 

                                <div className="form-group">
                                    {/* <label htmlFor="email">Heading</label>
                                    <input id="name" name="heading" type="text" placeholder="Heading of fellow" className="form-control" value={Data.heading} onChange={handleHeading} /> */}
                                     <CKEditor
                                                 
                                                        editor ={ClassicEditor}
                                                        onChange={handleY}
                                                      
                                                          data={Data.heading?Data.heading:"<p>Heading of Fellow</p>"}
                                                        />
                                </div>
                                {/* <div className="form-group">

                                    <textarea id="message" name="details" className="form-control" placeholder="Details of Fellow" style={{ height: 150 }} required value={Data.details} onChange={handleDetails} />
                                </div> */}
                                 <CKEditor
                                                 
                                                        editor ={ClassicEditor}
                                                        onChange={handleX}
                                                     
                                                          data={Data.details?Data.details:"<p>Details of Fellow</p>"}
                                                        />
                                <div className="form-group">

                                    <input name="image" type="file" placeholder="Photo " className="form-control" onChange={handleImage} />

                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit} >Submit</button>

                                </div>
                            </form>
                        </div>
                    </div>








                </div>

            </div>
        </div >
    )
}
export default FellowsData;